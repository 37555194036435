<template>
  <div>
    <v-alert v-if="success" border="bottom" color="success" dark>
      {{ successMensaje }}
    </v-alert>
    <v-alert v-if="error" border="bottom" color="red" dark>
      <b>Ocurrió un error al guardar el usuario.</b> {{ errorMensaje }}
    </v-alert>
    <v-form v-model="valid" ref="form">
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            v-model="roles"
            :rules="nameRules"
            label="Nombre"
            required
            filled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="2">
          <v-btn
            v-if="!editar"
            color="primary"
            x-large
            :loading="loading"
            @click="guardar"
          >
            Guardar
          </v-btn>
          <v-btn
            v-if="editar"
            color="primary"
            x-large
            :loading="loading"
            @click="modificar"
          >
            Editar
          </v-btn>
        </v-col>
        <v-col md="2">
          <v-btn color="success" x-large @click="listado">Ver Listado</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { API_URL } from '@/common/config';

import axios from 'axios';

export default {
  data: () => ({
    loading: false,
    success: false,
    error: false,
    successMensaje: '',
    errorMensaje: '',
    roles: [],
    valid: false,
    email: '',
    password: '',
    rol: '',
    nameRules: [(v) => !!v || 'El campo nombre es obligatorio'],
    emailRules: [
      (v) => !!v || 'El correo electronico es obligatorio',
      (v) => /.+@.+/.test(v) || 'El correo electronico no es valido',
    ],
  }),
  props: ['editar'],
  methods: {
    resetForm() {
      this.$refs.form.reset();
    },
    async guardar() {
      this.error = false;
      this.success = false;
      this.loading = true;
      const data = {
        nombre: this.roles,
      };
      const url = `${API_URL}api/roles`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      await axios.post(url, data, header).then((response) => {
        if (response.data.status === 'success') {
          this.loading = false;
          this.success = true;
          this.successMensaje = response.data.message;
          this.resetForm();
        } else if (response.data.status === 'error') {
          this.error = true;
          this.errorMensaje = response.data.message;
          this.loading = false;
        }
      });
    },
    async modificar() {
      this.error = false;
      this.success = false;
      this.loading = true;
      console.log(this.roles);
      axios
        .post(`${API_URL}api/roles/edit`, {
          token: localStorage.getItem('token'),
          rol_id: this.$route.params.id,
          nombre: this.roles,
        })
        .then((response) => {
          if (response.data.status === 'success') {
            this.loading = false;
            this.success = true;
            this.successMensaje = response.data.message;
          } else if (response.data.status === 'error') {
            this.loading = false;
            this.error = true;
            this.errorMensaje = response.data.message;
          }
        });
    },
    listado() {
      this.$router.push(`/listado/${this.$route.params.model}/`);
    },
    async presentarDatos() {
      try {
        const token = localStorage.getItem('token');
        console.log(token);
        const header = {
          headers: {
            token,
          },
        };

        await axios
          .get(`${API_URL}api/roles/${this.$route.params.id}`, header)
          .then((response) => {
            console.log(response.data);
            this.roles = response.data.rol[0].nombre;
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    if (this.editar) {
      this.presentarDatos();
    }
  },
};
</script>
