<template>
  <v-container>
    <v-row>
      <v-breadcrumbs :items="bread" large></v-breadcrumbs>
    </v-row>
    <div v-if="titulo === 'Unidad Gestion'">
      <h1>Unidad de gesti&oacute;n</h1>
    </div>
    <div v-else-if="titulo === 'Documentos Fideicomiso'">
      <h1>Vincular documentaci&oacute;n</h1>
    </div>
    <div v-else-if="titulo === 'Configuracion Mensaje Notificacion'">
      <h1>Configuraci&oacute;n Mensaje Notificaci&oacute;n</h1>
    </div>
    <div v-else-if="titulo === 'Configuracion Notificaciones'">
      <h1>Configuración Notificaciones</h1>
    </div>
    <div v-else>
      <h1>{{ titulo }}</h1>
    </div>
    <br />
    <FormNuevoUsuario
      :editar="isEditing"
      v-if="redirect.usuarios"
    ></FormNuevoUsuario>
    <FormNuevoLinea :editar="isEditing" v-if="redirect.linea"></FormNuevoLinea>
    <FormNuevoFideicomiso
      :editar="isEditing"
      v-if="redirect.fideicomisos"
    ></FormNuevoFideicomiso>
    <FormNuevoDocumento
      :editar="isEditing"
      v-if="redirect.documentos"
    ></FormNuevoDocumento>
    <FormNuevoDocumentoFideicomiso
      :editar="isEditing"
      v-if="redirect.documentosFideicomisos"
    >
    </FormNuevoDocumentoFideicomiso>
    <FormNuevoRol :editar="isEditing" v-if="redirect.rol"></FormNuevoRol>
    <FormNuevoSectorProd
      :editar="isEditing"
      v-if="redirect.sectoresProductivos"
    >
    </FormNuevoSectorProd>
    <FormNuevoTipoServicio :editar="isEditing" v-if="redirect.tipoServicios">
    </FormNuevoTipoServicio>
    <FormNuevoTipoParametro :editar="isEditing" v-if="redirect.tipoParametros">
    </FormNuevoTipoParametro>
    <FormNuevoServicioParametro
      :editar="isEditing"
      v-if="redirect.servicioParametros"
    >
    </FormNuevoServicioParametro>
    <FormNuevoSolicitudEstado
      :editar="isEditing"
      v-if="redirect.solicitudEstado"
    >
    </FormNuevoSolicitudEstado>
    <FormNuevoCfgNotificacion
      :editar="isEditing"
      v-if="redirect.cfgNotificacion"
    >
    </FormNuevoCfgNotificacion>
    <FormNuevoCfgNotificacionMsg
      :editar="isEditing"
      v-if="redirect.cfgNotificacionMsg"
    >
    </FormNuevoCfgNotificacionMsg>
    <FormNuevoCreditoEstado :editar="isEditing" v-if="redirect.creditoEstado">
    </FormNuevoCreditoEstado>
  </v-container>
</template>
<script>
import { API_URL } from '@/common/config';

import FormNuevoUsuario from '@/components/Nuevo/Usuario.vue';
import FormNuevoLinea from '@/components/Nuevo/Linea.vue';
import FormNuevoFideicomiso from '@/components/Nuevo/Fideicomiso.vue';
import FormNuevoDocumentoFideicomiso from '@/components/Nuevo/DocumentoFideicomiso.vue';
import FormNuevoDocumento from '@/components/Nuevo/Documento.vue';
import FormNuevoRol from '@/components/Nuevo/Rol.vue';
import FormNuevoSectorProd from '@/components/Nuevo/SectorProd.vue';
import FormNuevoTipoServicio from '@/components/Nuevo/TipoServicio.vue';
import FormNuevoTipoParametro from '@/components/Nuevo/TipoParametro.vue';
import FormNuevoServicioParametro from '@/components/Nuevo/ServicioParametro.vue';
import FormNuevoSolicitudEstado from '@/components/Nuevo/SolicitudEstado.vue';
import FormNuevoCfgNotificacion from '@/components/Nuevo/CfgNotificacion.vue';
import FormNuevoCfgNotificacionMsg from '@/components/Nuevo/CfgNotificacionMsg.vue';
import FormNuevoCreditoEstado from '@/components/Nuevo/CreditoEstado.vue';
import { redireccion } from '../common/util';

const axios = require('axios');

export default {
  components: {
    FormNuevoUsuario,
    FormNuevoLinea,
    FormNuevoFideicomiso,
    FormNuevoDocumentoFideicomiso,
    FormNuevoDocumento,
    FormNuevoRol,
    FormNuevoSectorProd,
    FormNuevoTipoServicio,
    FormNuevoTipoParametro,
    FormNuevoServicioParametro,
    FormNuevoSolicitudEstado,
    FormNuevoCfgNotificacion,
    FormNuevoCfgNotificacionMsg,
    FormNuevoCreditoEstado,
  },
  data: () => ({
    isEditing: false,
    redirect: {
      usuarios: false,
      fideicomisos: false,
      documentos: false,
      documentosFideicomisos: false,
      rol: false,
      sectoresProductivos: false,
      tipoServicios: false,
      tipoParametros: false,
      servicioParametros: false,
      solicitudEstado: false,
      cfgNotificacion: false,
      cfgNotificacionMsg: false,
      creditoEstado: false,
    },
    loaded: false,
    loading: false,
    bread: [
      {
        text: 'Mendoza Fiduciaria',
        disabled: false,
        href: '/',
      },
      {
        text: 'Nuevo',
        disabled: true,
        href: '/',
      },
      {
        text: 'Usuario',
        disabled: true,
        href: '/',
      },
    ],
  }),
  methods: {},
  computed: {
    titulo() {
      let t = this.$route.params.model;
      if (t === 'servicio') {
        t = 'operatoria';
      }
      if (t === 'tipoServicios') {
        t = 'tipoOperatorias';
      }
      if (t === 'servicioParametros') {
        t = 'operatoriaParametros';
      }
      if (t === 'cfgNotificacion') {
        t = 'configuracionNotificaciones';
      }
      if (t === 'cfgNotificacionMsg') {
        t = 'configuracionMensajeNotificacion';
      }
      t = t.charAt(0).toUpperCase() + t.slice(1);
      return t.match(/[A-Z][a-z]+|[0-9]+/g).join(' ');
    },
  },
  async created() {
    if (typeof this.$route.params.id !== 'undefined') {
      this.isEditing = true;
    }

    
    this.bread[2].text = this.titulo;
    switch (this.$route.params.model) {
      case 'usuarios':
        this.redirect.usuarios = true;
        break;
      case 'unidadGestion':
        this.redirect.fideicomisos = true;
        break;
      case 'servicio':
        this.redirect.linea = true;
        break;
      case 'documentos':
        this.redirect.documentos = true;
        break;
      case 'documentosFideicomiso':
        this.redirect.documentosFideicomisos = true;
        break;
      case 'roles':
        this.redirect.rol = true;
        break;
      case 'tipoServicios':
        this.redirect.tipoServicios = true;
        break;
      case 'sectoresProductivos':
        this.redirect.sectoresProductivos = true;
        break;
      case 'tipoParametros':
        this.redirect.tipoParametros = true;
        break;
      case 'servicioParametros':
        this.redirect.servicioParametros = true;
        break;
      case 'solicitudEstado':
        this.redirect.solicitudEstado = true;
        break;
      case 'cfgNotificacion':
        this.redirect.cfgNotificacion = true;
        break;
      case 'cfgNotificacionMsg':
        this.redirect.cfgNotificacionMsg = true;
        break;
      case 'creditoEstado':
        this.redirect.creditoEstado = true;
        break;

      default:
        break;
    }
    const isValid = await redireccion();

    if (!isValid) {
      localStorage.removeItem('token');
      localStorage.clear();
      this.$router.push('/login');
    }
  },
};
</script>
