<template>
  <div>
    <v-alert v-if="success" border="bottom" color="success" dark>
      {{ successMensaje }}
    </v-alert>
    <v-alert v-if="editado" border="bottom" color="success" dark>
      {{ successMensaje }}
    </v-alert>
    <v-alert v-if="error" border="bottom" color="red" dark>
      <b>Ocurrió un error al guardar el usuario.</b> {{ errorMensaje }}
    </v-alert>
    <v-form v-model="valid" ref="form">
      <v-row>
        <label>&nbsp;Tipo de usuario:</label>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-autocomplete
            v-model="rol_id"
            :items="roles"
            filled
            required
            label="Rol"
            @change="modificarTipoCliente"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="9"></v-col>
      </v-row>
      <div v-if="rol_id === 3">
        <v-row>
          <v-col cols="3">
            <input
              type="radio"
              id="personaFisica"
              value="1"
              v-model="tipoCliente"
            />
            <label for="personaFisica">&nbsp;Persona f&iacute;sica</label>
          </v-col>
          <v-col cols="3">
            <input type="radio" id="empresa" value="0" v-model="tipoCliente" />
            <label for="empresa">&nbsp;Empresa</label>
          </v-col>
        </v-row>
      </div>
      <div v-if="tipoCliente === 1">
        <v-row>
          <v-col cols="3">
            <v-text-field
              label="Nombre"
              v-model="nombre"
              v-on:keyup.enter="register"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="Apellido"
              v-model="apellido"
              v-on:keyup.enter="register"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="Número de DNI"
              v-model="dni"
              v-on:keyup.enter="register"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select
              :items="sectorProductivos"
              label="Sector Productivo"
              v-model="sectorProductivo"
            >
            </v-select>
          </v-col>
        </v-row>
      </div>
      <div v-if="tipoCliente === 0">
        <v-row>
          <v-col cols="3">
            <v-text-field
              label="Razón social"
              v-model="razonSocial"
              v-on:keyup.enter="register"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="Número de CUIT"
              v-model="cuit"
              v-on:keyup.enter="register"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select
              :items="sectorProductivos"
              label="Sector Productivo"
              v-model="sectorProductivo"
            >
            </v-select>
          </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col cols="3">
          <v-text-field
            label="Dirección de e-mail"
            v-model="email"
            filled
            autocomplete="false"
            v-on:keyup.enter="register"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            label="Contraseña"
            type="password"
            v-model="password"
            filled
            autocomplete="new-password"
            v-on:keyup.enter="register"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <div v-if="rol_id === '3'">
            <v-autocomplete
              v-model="sectorProductivo"
              :items="sectorProductivos"
              required
              label="Sector productivo"
            >
            </v-autocomplete>
          </div>
        </v-col>
        <v-col cols="3"></v-col>
      </v-row>
      <v-row>
        <v-col md="2">
          <v-btn
            v-if="!editar"
            color="primary"
            x-large
            :loading="loading"
            @click="guardar"
          >
            Guardar
          </v-btn>
          <v-btn
            v-if="editar"
            color="primary"
            x-large
            :loading="loading"
            @click="modificar"
          >
            Editar
          </v-btn>
        </v-col>
        <v-col md="2">
          <v-btn color="success" x-large @click="listado">Ver Listado </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import { API_URL } from '@/common/config';

import axios from 'axios';

export default {
  data: () => ({
    rol_id: '',
    roles: [],
    email: '',
    password: '',
    nombre: '',
    apellido: '',
    cuit: '',
    dni: '',
    razonSocial: '',
    sectorProductivo: '',
    sectorProductivos: [],
    tipoCliente: 2,
    loading: false,
    success: false,
    successMensaje: '',
    error: false,
    errorMensaje: '',
    valid: false,
    editado: false,
    nameRules: [(v) => !!v || 'El campo nombre es obligatorio'],
    emailRules: [
      (v) => !!v || 'El correo electronico es obligatorio',
      (v) => /.+@.+/.test(v) || 'El correo electronico no es valido',
    ],
  }),
  props: ['editar'],
  methods: {
    async modificarTipoCliente() {
      if (this.rol_id === 3) {
        this.tipoCliente = 1;
      } else {
        this.tipoCliente = 2;
      }
    },
    async loadSectorProductivos() {
      const url = `${API_URL}api/sectoresProductivos`;
      await axios
        .get(url)
        .then((response) => {
          if (response.data.status === 'success') {
            this.sectorProductivos = response.data.sectoresProductivos.map(
              (f) => ({ value: f.id, text: f.nombre })
            );
          } else {
            console.log('Error al obtener los datos');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetForm() {
      this.$refs.form.reset();
      this.tipoCliente = 2;
    },
    listado() {
      this.$router.push(`/listado/${this.$route.params.model}/`);
    },
    async guardar() {
      try {
        this.loading = true;
        this.success = false;
        this.error = false;

        // 1. Obtener el token del almacenamiento local
        const token = localStorage.getItem('token');
        if (!token) {
          // Manejar el caso en que no hay token (redirigir o mostrar un error)
          // ...
          return; // O terminar la ejecución de la función
        }

        // 2. Preparar los datos para la solicitud POST
        const data = {
          email: this.email,
          password: this.password,
          rol: this.rol_id,
          telefono: '',
        };
        const url = `${API_URL}api/auth/`;
        // 3. Realizar la solicitud POST

        const response = await axios.post(url, data, { headers: { token } });

        // 4. Manejar la respuesta del servidor
        if (response.data.status === 'success') {
          this.success = true;
          this.successMensaje = response.data.message;
        } else if (response.data.status === 'error') {
          this.error = true;

          this.errorMensaje = response.data.message;
        }

        // 5. Resetear el formulario y actualizar el estado de carga
        this.resetForm();
        this.loading = false;
      } catch (error) {
        const { message } = error.response.data;
        console.log('responsesss', message);
        // 6. Manejar errores generales
        console.error('Error al guardar el usuario:', message);
        this.loading = false;
        this.error = true;

        this.errorMensaje = `Error al guardar el usuario:${message}`; // Mostrar un mensaje de error general
      }
    },

    async modificar() {
      try {
        this.loading = true;

        const token = localStorage.getItem('token');
        if (!token) {
          return;
        }

        // 2. Preparar los datos para la solicitud PUT
        const userData = {
          email: this.email,
          password: this.password,
          rol_id: this.rol_id,
        };
        const clientData = {
          nombre: this.nombre,
          apellido: this.apellido,
          dni: this.dni,
          cuit: this.cuit,
          sectorProductivo: this.sectorProductivo,
          razonSocial: this.razonSocial,
        };

        // 3. Realizar la solicitud PUT para actualizar el usuario
        const responseUser = await axios.put(
          `${API_URL}api/auth/${this.$route.params.id}`,
          userData,
          { headers: { token } }
        );

        // 4. Verificar si la actualización del usuario fue exitosa
        if (responseUser.data.status === 'success') {
          // 5. Actualizar la información del cliente
          const responseClient = await axios.put(
            `${API_URL}api/clientes/${this.$route.params.id}`,
            clientData,
            { headers: { token } }
          );

          // 6. Mostrar el mensaje de éxito
          this.successMensaje = responseClient.data.message;
        } else {
          if (responseClient.data.message === 'No posee cliente') {
            this.successMensaje = responseClient.data.message;
          }
        }
        // 8. Actualizar el estado de la interfaz
        this.loading = false;
        this.editado = true;
      } catch (error) {
        const { message } = error.response.data;
        // 9. Manejar errores generales
        console.error('Error al modificar el usuario:', message);
        this.loading = false;
        this.errorMessage = `Error al modificar el usuario:${message}`; // Mostrar un mensaje de error general
      }
    },

    async presentarDatos() {
      await axios
        .get(`${API_URL}api/usuarios/${this.$route.params.id}`, {
          headers: {
            token: localStorage.getItem('token'),
          },
        })
        .then((response) => {
          const cliente = response.data.client;
          this.email = response.data.user.email;
          this.password = response.data.user.password;
          this.rol_id = response.data.user.rol_id;

          if (cliente.length > 0) {
            this.tipoCliente = cliente[0].tipoCliente;
            this.sectorProductivo = cliente[0].sectorProductivo;
            if (cliente[0].tipoCliente === 1) {
              this.nombre = cliente[0].nombre;
              this.apellido = cliente[0].apellido;
              this.dni = cliente[0].dni;
            } else {
              this.razonSocial = cliente[0].razonSocial;
              this.cuit = cliente[0].cuit;
            }
          }
        });
    },
    async getRoles() {
      const url = `${API_URL}api/roles`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      axios.get(url, header).then((result) => {
        if (result.data.status === 'success') {
          this.roles = result.data.roles.map((r) => ({
            value: r.id,
            text: r.nombre,
          }));
        }
      });
    },
  },
  created() {
    this.loadSectorProductivos();
    this.getRoles();
    if (this.editar) {
      this.presentarDatos();
    }
  },
};
</script>
